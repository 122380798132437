import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import "./PMFooter.scss";

function Footer() {
  return (
    <>
      <Grid item xs={12}>
        <div className="footer-wrapper">
          <div>
            <span className="copyright-text">
              &#169; 2025 PediaMetrix. All Rights Reserved
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default Footer;
